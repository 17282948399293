<template lang="html">
  <section class="about-us">
    <section class="slider-full">
      <div class="slide ">
        <div class="d-flex jcsb container-slide container aic">
          <div class="div-text ">
            <Waypoint>
              <h1 class="title-big -whiteC">
                {{$t("about.intro1")}}
              </h1>
            </Waypoint>
            <Waypoint>
              <p class="-whiteC">
                {{$t("about.texto1")}}
              </p>
              <p class="-whiteC"> {{$t("about.texto2")}}
              </p>
            </Waypoint>

          </div>

          <div class="div-image">
            <Vue3Lottie :animationData="headAbout" height="auto" width="100%" :loop="1" />
          </div>
        </div>

      </div>
    </section>
    <section class="info-company">
      <div class="container d-flex aic jcc">


        <Waypoint effect="fadeIn" class="div-img">
          <img src="/img/illustrations/about-1.svg" alt="ilustración about us">
        </Waypoint>


        <div class="content ">
          <h2 class="title-medium "> {{$t("about.intro2")}}</h2>

          <p class="-blueDarkC ">
            {{$t("about.texto1_2")}}<strong>{{$t("about.texto1_2_s1")}}</strong>{{$t("about.texto1_2_2")}}<strong>{{$t("about.texto1_2_s3")}}</strong>{{$t("about.texto1_2_4")}}<strong>{{$t("about.texto1_2_s5")}}</strong>{{$t("about.texto1_2_6")}}<strong>{{$t("about.texto1_2_s7")}}</strong>{{$t("about.texto1_2_8")}}
          </p>
        </div>
      </div>
      <div class="container d-flex aic jcc  -reverse">
        <div class="content">
          <p class="-blueDarkC ">
            {{$t("about.texto2_2")}}<strong>{{$t("about.texto2_2_s1")}}</strong>{{$t("about.texto2_2_2")}}<strong>{{$t("about.texto2_2_s2")}}</strong>{{$t("about.texto2_2_3")}}<strong>{{$t("about.texto2_2_s4")}}</strong>{{$t("about.texto2_2_5")}}<strong>{{$t("about.texto2_2_s6")}}</strong>
          </p>

        </div>
        <Waypoint effect="fadeIn" class="div-img">
          <img src="/img/illustrations/about-2.svg" alt="ilustración about us 2">
        </Waypoint>
      </div>
    </section>
    <reserva></reserva>
    <section class="box-founders">
      <Waypoint id="line">
        <div class="detail1">
          <Vue3Lottie v-if="animationState.line" :animationData="LineBlue" :height="auto" width="100%"
            direction="forward" />
        </div>
      </Waypoint>
      <Waypoint id="line2">
        <div class="detail2">
          <Vue3Lottie v-if="animationState.line2" :animationData="LineBlue2" :height="auto" width="100%"
            direction="forward" />
        </div>
      </Waypoint>

      <div class="container">
        <img src="/img/icons/detail-circle.svg" alt="detail-circle" class="detail">
        <h2 class="title-big -blueDarkC"> {{$t("about.founders")}}</h2>
        <div class="container-founders">
          <Waypoint effect="fadeIn" class="founder">
            <img src="/img/bg/ramon.png" alt="RAMON FERRER" class="img-member">
            <p class="title-small -blueC">RAMON FERRER </p>
            <p class="title-small -blueDarkC">CEO</p>
            <a href="https://www.linkedin.com/in/ramonferrer/" target="_blank">
              <img src="/img/icons/linkedin-blue.svg" alt="icono linkedin">
            </a>
          </Waypoint>
          <Waypoint effect="fadeIn" class="founder">
            <img src="/img/bg/javier.png" alt="JAVIER MARTÍNEZ" class="img-member">
            <p class="title-small -blueC">JAVIER MARTÍNEZ </p>
            <p class="title-small -blueDarkC">CPO</p>
            <a href="https://www.linkedin.com/in/javiermartinezbajo/" target="_blank">
              <img src="/img/icons/linkedin-blue.svg" alt="icono linkedin">
            </a>
          </Waypoint>
          <Waypoint effect="fadeIn" class="founder">
            <img src="/img/bg/lee.png" alt="LEE MAISNER" class="img-member">
            <p class="title-small -blueC">LEE MAISNER</p>
            <p class="title-small -blueDarkC">CTO</p>
            <a href="https://www.linkedin.com/in/lee-maisner-601184/" target="_blank">
              <img src="/img/icons/linkedin-blue.svg" alt="icono linkedin">
            </a>
          </Waypoint>
          <Waypoint effect="fadeIn" class="founder">
            <img src="/img/bg/juan.png" alt="JUAN CARLOS TORAL" class="img-member">
            <p class="title-small -blueC">JUAN CARLOS TORAL</p>
            <p class="title-small -blueDarkC">CFO</p>
            <a href="https://www.linkedin.com/in/juan-carlos-toral-gonzalez-85030b54/" target="_blank">
              <img src="/img/icons/linkedin-blue.svg" alt="icono linkedin">
            </a>
          </Waypoint>
        

        </div>
      </div>
    </section>
    <section class="partner">
      <div class="container d-flex aife jcsb">
        <Waypoint effect="fadeInLeft" class="text">
          <h2 class="title-medium -blueC"> {{$t("about.partners")}}</h2>
          <p> {{$t("about.partners_text")}}</p>
        </Waypoint>
        <Waypoint effect="fadeInRight" class="container-logos">

        
          <a href="https://www.fortech.ro/" target="_blank" class="logo">
            <img src="/img/icons/fortech.svg" alt="Fortech">
          </a>
        
          <a href="https://doubledot.es/" target="_blank" class="logo">
            <img src="/img/icons/doubledot.svg" alt="Doubledot">
          </a>
        </Waypoint>
      </div>
    </section>
    <newsletter></newsletter>
  </section>

</template>

<script lang="js">
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import LineBlue from '@/lotties/line_1_blue.json'
import LineBlue2 from '@/lotties/line_2_blue.json'
import headAbout from '@/lotties/head-about.json'
import { Waypoint } from 'vue-waypoint'
import newsletter from '@/components/newsletter'
import reserva from '@/components/reserva'

export default {
  name: 'about-us',
  props: [],
  components: {
    Waypoint,
    Vue3Lottie,
    'newsletter': newsletter,
    'reserva': reserva

  },
  mounted() {

  },
  data() {
    return {
      animationState: {
        line: false,
        line2: false
      },
      headAbout,
      LineBlue,
      LineBlue2
    }
  },
  methods: {
    onChange(waypointState) {
      if (this.animationState[waypointState.el.id] === false) {
        this.animationState[waypointState.el.id] = waypointState.going == 'IN'
      }
    }
  },
  computed: {

  }
}


</script>

<style scoped lang="scss">
.about-us {}
.info-company .container .content {
    width: 75%;
    padding-left: 35px;
}
.info-company .container+.container {
  padding-bottom: 120px
}

@media screen and (max-width:890px) {
  .info-company .container .content {
    width: 100%;
    padding-left: 0px;
}
  .info-company .container+.container {
    padding-bottom: 0px !important;
  }

  .about-us .slider-full .slide .container-slide .div-text {

    padding-left: 0px !important;
  }

  p {
    text-align: center;
  }


  .logo:nth-child(2) img{
    margin-bottom:23px;

  }
}
</style>
<template lang="html">

  <section class="newsletter">

    <div class="container grid-2 demo ">

      <div class="content demo2 grid">
        <p class="title-big -blueC">{{ $t('newsletter.text') }}</p><br>
        <p class="subtitle -blueDarkC">{{ $t('newsletter.title') }}</p>
        <br>
        <ul>
          <li v-for="(literal, key) in $tm('newsletter.li')" :key="key" class="espacio">{{ literal}}</li><br>
        </ul>


      </div>

      <div class="content demo2 grid">
        <!-- TODO Dynamic load -->
        <template v-if="$i18n.locale == 'es'">
          <div class="_form_1"></div>
          <component :is="'script'" src="https://r-sami.activehosted.com/f/embed.php?id=1" type="text/javascript" charset="utf-8"></component>
        </template>
        <template v-else-if="$i18n.locale == 'en'">
            <div class="_form_4"></div>
            <component :is="'script'" src="https://r-sami.activehosted.com/f/embed.php?id=4" type="text/javascript" charset="utf-8"></component>
        </template>
        <template v-else-if="$i18n.locale == 'it'">
          <div class="_form_6"></div>
          <component :is="'script'" src="https://r-sami.activehosted.com/f/embed.php?id=6" type="text/javascript" charset="utf-8"></component>
        </template>
        <template v-else-if="$i18n.locale == 'de'">
            <div class="_form_4"></div>
            <component :is="'script'" src="https://r-sami.activehosted.com/f/embed.php?id=4" type="text/javascript" charset="utf-8"></component>
        </template>
        <template v-else-if="$i18n.locale == 'nl'">
            <div class="_form_4"></div>
            <component :is="'script'" src="https://r-sami.activehosted.com/f/embed.php?id=4" type="text/javascript" charset="utf-8"></component>
        </template>
        <template v-else-if="$i18n.locale == 'fr'">
            <div class="_form_4"></div>
            <component :is="'script'" src="https://r-sami.activehosted.com/f/embed.php?id=4" type="text/javascript" charset="utf-8"></component>
        </template>

      </div>
    </div>
  </section>
</template>

<script lang="js">
  export default {
    name: 'newsletter',
    props: []
  }

</script>

<style scoped lang="scss">
.grid-2 {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}

.newsletter .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;

  & p,
  ul,
  li {
    text-align: left;
  }

  li {
    color: #1E2F3F;
    font-weight: 700;
    text-align: left;
    font-size: 17px;
  }

  .subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4em;
  }

  & .title-big {
    font-size: 30px
  }
}

@media screen and (max-width:900px) {
  .grid-2 {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }

  .newsletter .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 20px;

  }
}
</style>
<template lang="html">

    <section class="reserva-demo ">
        <div class="container">
          <div class="content">
            <p>{{$t('demo.reservation.text')}}</p>
            <router-link :to="{ name: 'demo' }" class="btn-cta"><strong>{{  $t('demo.reservation.button')  }}</strong><img src="/img/flecha-blanca.svg" alt="flecha blanca"></router-link>
          </div>
          <div class="content">
            <img src="/img/animacion-demo.svg" alt="animación demo">
          </div>
        </div>
      </section>

</template>
  
  <script lang="js">




</script>
  
  <style scoped lang="scss">

  </style>